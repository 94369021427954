<!--
 * @Author: 王昌浩
 * @Date: 2020-07-29 16:06:01
 * @LastEditTime: 2021-03-05 11:09:49
 * @LastEditors: Please set LastEditors
 * @Description: 泊位状态监控
 * @FilePath: \trunk\src\pages\equipmentState\berthMonitor\berthStateMonitor.vue
-->
<!-- 泊位状态监控 -->
<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Berth_number')">
                <el-input
                  placeholder="泊位号"
                  maxlength="30"
                  v-model="formInline.berthCode"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Equipment_number')">
                <el-input
                  v-model.trim="formInline.equipmentCode"
                  maxlength="30"
                  placeholder="请输入设备编号"
                ></el-input>
              </el-form-item>
              <el-form-item label="所在道路">
                <my-component
                  ref="parkInput"
                  @valueChange="completeValue"
                  slaveRelations="0,1"
                  :commercialStatus="'2,3'"
                >
                </my-component>
                <!-- <el-select
              v-model.trim="formInline.parentId"
              filterable
              placeholder="请选择"
              @change="slaveRelationChange"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.parkName"
                :value="value.parkId"
                :key="value.parkId"
                v-for="value in parkParent"
              ></el-option>
            </el-select> -->
              </el-form-item>
              <el-form-item :label="$t('searchModule.state')">
                <el-select v-model.trim="formInline.type" filterable placeholder="请选择">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="正常" value="1"></el-option>
                  <el-option label="异常" value="0"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          ></el-table-column>
          <!-- v-if="$route.meta.authority.button.detail" -->
          <el-table-column align="center" label="操作" width="200" type="index">
            <template slot-scope="scope">
              <el-button
                type="text"
                style="padding: 0"
                v-show="scope.row.berthStatus == '0'"
                @click="handleClick(scope.row)"
              >
                查看异常状态
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagerWrapper">
          <div class="block" style="text-align: right">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <!--分页器-->
    </div>
  </div>
</template>

<script>
import myComponent from "@/components/autocomplete/myautoComponent";
export default {
  components: { myComponent },
  data() {
    return {
      formInline: {
        berthCode: "",
        equipmentCode: "",
        parentId: "",
        parkName: "",
        type: "",
      },
      tableCols: [
        {
          prop: "berthCode",
          label: this.$t("list.Berth_number"),
          width: "",
        },
        {
          prop: "equipmentName",
          label: "对应视频设备名称",
          width: "",
        },
        {
          prop: "equipmentCode",
          label: "对应视频设备编号",
          width: "",
        },
        {
          prop: "parentParkName",
          label: "所在道路",
          width: "",
        },
        {
          prop: "berthStatus",
          label: this.$t("list.state"),
          width: "",
          formatter: (type) => {
            if (type.berthStatus == 0) {
              return "异常";
            } else {
              return "正常";
            }
          },
        },
      ],
      tableData: [],
      parkParent: [], // 父车场
      page: 1,
      pageSize: 15,
      total: 0,
      loading: false,
      parentObj: {},
    };
  },
  // 方法集合
  methods: {
    completeValue(item) {
      if (item) {
        this.formInline.parentId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parentId = "";
        this.formInline.parkName = "";
      }
    },
    searchData() {
      // GET /berthStatus/pageBerthStatus
      let url = "/acb/2.0/berthStatus/pageBerthStatus";
      this.$axios
        .get(url, {
          data: {
            berthCode: this.formInline.berthCode,
            // 泊位编码
            equipmentCode: this.formInline.equipmentCode,
            // 视频设备编号
            parentParkId: this.formInline.parentId,
            // 父车场ID
            parentParkName: this.formInline.parkName,
            // 父车场名称
            berthStatus: this.formInline.type,
            // 泊位状态0异常1正常
            pageNum: this.page,
            // 当前页,默认第一页
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    getParentPark() {
      let url = "/acb/2.0/park/getParentParks";
      this.$axios
        .get(url, {
          data: {
            slaveRelations: "0,1",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.parkParent = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleClick(v) {
      this.$router.push({
        path: "/berthStateMonitorDetail",
        query: v,
      });
    },
    handleCurrentChange(v) {
      this.page = v;
      this.searchData();
    },
    // slaveRelationChange(item) {
    //   if (!item) {
    //     this.parentObj.parkId = "";
    //     this.parentObj.parkName = "";
    //     return false;
    //   }
    //   let obj = {};
    //   obj = this.parkParent.find((v) => {
    //     return item === v.parkId;
    //   });
    //   this.parentObj = obj;
    // },
  },
  created() {},
  mounted() {
    this.getParentPark();
    this.searchData();
  },
};
</script>
<style lang="stylus" scoped>
  .pagerWrapper
    text-align right
    margin-top 18px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
// @import url(); 引入公共css类
</style>
